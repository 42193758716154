<template>
  <LostPassword :cardHeading="$t('Reset my password')">
    <i18n class="text-secondary" tag="p" path="lostPassword.emailText"></i18n>
    <Form :formFields="formFields" @on-submit="onSubmit" :stacked="true">
      <template v-slot:formfooter>
        <slot>
          <div class="row mt-0">
            <div class="col-md-12 pt-0">
              <p class="text-danger" v-if="getErrors.resetPass.length">
                {{ getErrors.resetPass }}
              </p>
              <button class="btn btn-primary btn-block" :disabled="getLoadings.resetPass">
                {{ $t('lostPassword.sendLink') }}
                <b-spinner v-if="getLoadings.resetPass" small label="Spinning" variant="white"></b-spinner>
              </button>
            </div>
          </div>
        </slot>
      </template>
    </Form>
  </LostPassword>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LostPassword from "../components/LostPassword/LostPassword.vue";
import Form from "../components/ui/form/Form.vue";

export default {
  components: { LostPassword, Form },
  data() {
    return {
      formFields: [
        {
          component: "TextInput",
          name: "email",
          placeholder: this.$t("emailPlaceholder"),
          label: this.$t("emailPlaceholder"),
          rules: "email|required",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  methods: {
    ...mapActions("auth", ["forgotPassword"]),
    async onSubmit(data) {
      const type = this.$route.query.type;
      await this.forgotPassword({
        body: { type, email: data.email },
      });
      this.$router.push(`/lostPassword/message?email=${data.email}`);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>